import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import i18next from 'i18next'

import { setTmpPin,	setTmpTwoFactorCode } from '../../actions/localStates'

class PinPopup extends Component {
	constructor(props) {
	    super(props);
	    this.state = {
	    	showPassword: false
	    }

	    this.handlePinChange = this.handlePinChange.bind(this);
	    this.handleTwoFactorCodeChange = this.handleTwoFactorCodeChange.bind(this);

	    // this.create = this.close.bind(this);
	    // this.close = this.close.bind(this);
	}

	componentDidMount() {

	}

	setShowPassword() {
	    this.setState({showPassword: this.state.showPassword ? false : true})
	}

	hover() {
	    this.setState({showPassword: true})
	}

	out() {
	    this.setState({showPassword: false})
	}

	create () {
		this.props.createDSUsingPin()
	}

	close () {
		this.props.closePinPopup()
	}

	handlePinChange (e) {
		this.props.actions.setTmpPin(e.target.value)
	}

	handleTwoFactorCodeChange (e) {
		this.props.actions.setTmpTwoFactorCode(e.target.value)
	}

	render() {
		let isEnabled = this.props.pkLocalState.useTwoFactorCode ? (this.props.pkLocalState.tmpPin.length > 0 && this.props.pkLocalState.tmpTwoFactorCode.length > 0) : this.props.pkLocalState.tmpPin.length > 0
		return (
			<div className='popup'>
				<div className="popup_inner">
					<div className="card">
						<div className="card-header">
						  
						</div>
						<div className="card-body">
							<div className="form-group row">
	                            <label className="col-lg-4 col-md-12 col-xs-12 col-sm-12 col-form-label">{this.props.pkLocalState.depositsignKeysInfo !== null ? i18next.t("pk:pinForKredo") : i18next.t("pk:pinLabel")}</label>
								<div className="col-lg-8 col-md-12 col-xs-12 col-sm-12">
									<div className="input-group">
										<input className="form-control" value={this.props.pkLocalState.tmpPin} type={this.state.showPassword ? "input" : "password"} onChange={this.handlePinChange} />
	                                      	<div className="input-group-append">
	                                            <div className="input-group-text" onClick={this.setShowPassword.bind(this)} onMouseOver={this.hover.bind(this)} onMouseOut={this.out.bind(this)}><i className="fa fa-eye"></i></div>
	                                      	</div>
									</div>
								</div>
	                        </div>
	                        {
	                        	this.props.pkLocalState.useTwoFactorCode
	                        	? <div className="form-group row">
		                            <label className="col-lg-4 col-md-12 col-xs-12 col-sm-12 col-form-label">{i18next.t("pk:oneTimePassLabel")}</label>
									<div className="col-lg-8 col-md-12 col-xs-12 col-sm-12">
										<div className="input-group">
										    <input type="text" className="form-control" value={this.props.pkLocalState.tmpTwoFactorCode} onChange={this.handleTwoFactorCodeChange}/>
										</div>
									</div>
		                        </div>
		                        : null
	                        }
							<div className="row">
			                    <div className="col-md-6 col-xs-12 col-sm-12">
			                    	<button className="btn btn-default btn-block" disabled={!isEnabled} onClick={this.create.bind(this)} style={{marginBottom: "10px"}}>{i18next.t("pk:selectTitle")}</button>
			                    </div>
			                    <div className="col-md-6 col-xs-12 col-sm-12">
			                    	<button className="btn btn-default btn-block" onClick={this.close.bind(this)}>{i18next.t("closeLabel")}</button>
			                    </div>
			                </div>
		                </div>
		            </div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        pkLocalState: state.privateKeyReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
    	setTmpPin,
		setTmpTwoFactorCode
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PinPopup);