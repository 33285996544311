import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18next from 'i18next'
import { bindActionCreators } from 'redux'

class CertificateInfoSignature extends Component {

  formatDate(dateInString) {
      var localeDateOptions = {
          "day": "numeric",
          "month": "numeric",
          "year": "numeric",
          "hour": "2-digit",
          "minute": "2-digit",
          "second": "2-digit",
          "timeZoneName": "short"
      }

      return new Date(dateInString).toLocaleDateString(i18next.language, localeDateOptions);
  }

  _renderCertInfo() {
    let certInfo = this.props.base.isVtco ? this.props.certificateInfo.subject : this.props.certificateInfo.ownerCertificateInfo.value
    let crtInfo = [];
    for (var i in certInfo) {
        if (this.props.base.isVtco) {
          if (certInfo[i] != null) {
            var str = i
            crtInfo.push(
              <div className="input-group">
                  <label className="col-md-4 col-lg-4 col-sm-12 col-xs-12 font-weight-bold margin-bottom-sm">{i18next.t(str)}</label>
                    <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12 margin-bottom-8">
                      {certInfo[i]}
                    </div>
              </div>
            )
          }
        } else {
          if (certInfo[i].value != null) {
            crtInfo.push(
              <div className="input-group">
                  <label className="col-md-4 col-lg-4 col-sm-12 col-xs-12 font-weight-bold margin-bottom-sm">{certInfo[i].description}</label>
                    <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12 margin-bottom-8">
                      {certInfo[i].value}
                    </div>
              </div>
            )
          }
        }
    }

    if (this.props.base.isVtco) {
      crtInfo.push(<div className="input-group">
          <label className="col-md-4 col-lg-4 col-sm-12 col-xs-12 font-weight-bold margin-bottom-sm">{i18next.t("drfou")}</label>
            <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12 margin-bottom-8">
              {this.props.certificateInfo.extensions.drfou}
            </div>
      </div>)
    } else {
      crtInfo.push(<div className="input-group">
          <label className="col-md-4 col-lg-4 col-sm-12 col-xs-12 font-weight-bold margin-bottom-sm">{this.props.certificateInfo.extensionsCertificateInfo.value.personalData.value.drfou.description}</label>
            <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12 margin-bottom-8">
              {this.props.certificateInfo.extensionsCertificateInfo.value.personalData.value.drfou.value}
            </div>
      </div>)
    }

    return crtInfo;
  }

  _renderIssuerInfo() {
    let issuerInfo = this.props.base.isVtco ? this.props.certificateInfo.issuer : this.props.certificateInfo.issuerCertificateInfo.value
    let isrInfo = [];
    for (var i in issuerInfo) {
        if (this.props.base.isVtco) {
          if (issuerInfo[i] != null) {
            var str = i
            isrInfo.push(
              <div className="input-group">
                  <label className="col-md-4 col-lg-4 col-sm-12 col-xs-12 font-weight-bold margin-bottom-sm">{i18next.t(str)}</label>
                    <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12 margin-bottom-8">
                      {issuerInfo[i]}
                    </div>
              </div>
            )
          }
      } else {
        if (issuerInfo[i].value != null) {
          isrInfo.push(
            <div className="input-group">
                <label className="col-md-4 col-lg-4 col-sm-12 col-xs-12 font-weight-bold margin-bottom-sm">{issuerInfo[i].description}</label>
                  <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12 margin-bottom-8">
                    {issuerInfo[i].value}
                  </div>
            </div>
          )
        }
      }
    }
    return isrInfo;
  }

  _renderPublicKeyInfo() {
    let publicKeyInfo = this.props.base.isVtco ? this.props.certificateInfo.subjectPublicKeyInfo : this.props.certificateInfo.publicKeyCertificateInfo.value
    let pkInfo = [];
    for (var i in publicKeyInfo) {
        if (this.props.base.isVtco) {
          if (publicKeyInfo[i] != null) {
            var str = i
            pkInfo.push(
              <div className="input-group">
                  <label className="col-md-4 col-lg-4 col-sm-12 col-xs-12 font-weight-bold margin-bottom-sm">{i18next.t(str)}</label>
                    <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12 margin-bottom-8">
                      {
                        i === "algorithm"
                        ? (publicKeyInfo[i] === "1.2.840.10045.2.1" ? i18next.t("ECDSA-256") : (publicKeyInfo[i] === "1.2.840.113549.1.1.1" ? i18next.t("RSA") : publicKeyInfo[i]))
                        : publicKeyInfo[i]
                      }
                    </div>
              </div>
            )
          }
        } else {
          if (publicKeyInfo[i].value != null) {
            pkInfo.push(
              <div className="input-group">
                  <label className="col-md-4 col-lg-4 col-sm-12 col-xs-12 font-weight-bold margin-bottom-sm">{publicKeyInfo[i].description}</label>
                    <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12 margin-bottom-8">
                      {
                        i === "algorithm"
                        ? (publicKeyInfo[i].value === "1.2.840.10045.2.1" ? i18next.t("ECDSA-256") : (publicKeyInfo[i].value === "1.2.840.113549.1.1.1" ? i18next.t("RSA") : publicKeyInfo[i].value))
                        : publicKeyInfo[i].value
                      }
                    </div>
              </div>
            )
          }
        }
    }
    return pkInfo;
  }

  getBlobData() {
      var saveBlob = (function () {
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          return function (blob, fileName) {
                
              var url = window.URL.createObjectURL(blob);
              a.href = url;
              a.download = fileName.lastIndexOf(".") < 2 ? fileName : fileName.substr(0, fileName.lastIndexOf("."));
              a.click();
              window.URL.revokeObjectURL(url);
          };
      }());

      saveBlob(this.state.blobData, this.state.fileForDecryptName);
  }

  render() {
    return (
      this.props.certificateInfo ?
        <div className="col-md-12 col-lg-8 col-sm-12 col-xs-12">
                  <div className="card">

                  {
                    this.props.base.isVtco
                    ? <div className="card-body left-padding-5">
                          <div className="input-group">
                              <label className="col-md-4 col-lg-4 col-sm-12 col-xs-12 font-weight-bold margin-bottom-sm">{i18next.t("serialNumber")}</label>
                                <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12 margin-bottom-8">
                                  {this.props.certificateInfo.serialNumber}
                                </div>
                          </div>
                          <div className="input-group">
                              <label className="col-md-4 col-lg-4 col-sm-12 col-xs-12 font-weight-bold margin-bottom-sm">{i18next.t("notBefore")}</label>
                                <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12 margin-bottom-8">
                                  {this.props.certificateInfo.notBefore}
                                </div>
                          </div>
                          <div className="input-group">
                              <label className="col-md-4 col-lg-4 col-sm-12 col-xs-12 font-weight-bold margin-bottom-sm">{i18next.t("notAfter")}</label>
                                <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12 margin-bottom-8">
                                  {this.props.certificateInfo.notAfter}
                                </div>
                          </div>
                          {
                            this.props.certificateInfo.extensions.isElectronicStamp !== undefined
                            ? <div className="input-group">
                                  <label className="col-md-4 col-lg-4 col-sm-12 col-xs-12 font-weight-bold margin-bottom-sm">{i18next.t("isElectronicStamp")}</label>
                                    <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12 margin-bottom-8">
                                      {this.props.certificateInfo.extensions.isElectronicStamp === true ? i18next.t("yesUCase") : i18next.t("noUCase")}
                                    </div>
                              </div>
                            : null
                          }
                          
                          <div className="input-group">
                              <label className="col-md-4 col-lg-4 col-sm-12 col-xs-12 font-weight-bold margin-bottom-sm">{i18next.t("isQualified")}</label>
                                <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12 margin-bottom-8">
                                  {this.props.certificateInfo.extensions.isQualified === true ? i18next.t("yesUCase") : i18next.t("noUCase")}
                                </div>
                          </div>
                          <div className="input-group">
                              <label className="col-md-4 col-lg-4 col-sm-12 col-xs-12 font-weight-bold margin-bottom-sm">{i18next.t("isPrivateKeyOnQSCD")}</label>
                                <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12 margin-bottom-8">
                                  {this.props.certificateInfo.extensions.isPrivateKeyOnQSCD === true ? i18next.t("yesUCase") : i18next.t("noUCase")}
                                </div>
                          </div>
                          <div className="input-group">
                              <label className="col-md-4 col-lg-4 col-sm-12 col-xs-12 font-weight-bold margin-bottom-sm">{i18next.t("qscdTypeName")}</label>
                                <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12 margin-bottom-8">
                                  {this.props.certificateInfo.extensions.qscdTypeName === null ? i18next.t("ds:notDefined") : this.props.certificateInfo.extensions.qscdTypeName}
                                </div>
                          </div>
                      </div>
                    : <div className="card-body left-padding-5">
                        <div className="input-group">
                            <label className="col-md-4 col-lg-4 col-sm-12 col-xs-12 font-weight-bold margin-bottom-sm">{this.props.certificateInfo.certificateSerialNumber.description}</label>
                              <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12 margin-bottom-8">
                                {this.props.certificateInfo.certificateSerialNumber.value}
                              </div>
                        </div>
                        <div className="input-group">
                            <label className="col-md-4 col-lg-4 col-sm-12 col-xs-12 font-weight-bold margin-bottom-sm">{this.props.certificateInfo.notBefore.description}</label>
                              <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12 margin-bottom-8">
                                {this.props.certificateInfo.notBefore.value}
                              </div>
                        </div>
                        <div className="input-group">
                            <label className="col-md-4 col-lg-4 col-sm-12 col-xs-12 font-weight-bold margin-bottom-sm">{this.props.certificateInfo.notAfter.description}</label>
                              <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12 margin-bottom-8">
                                {this.props.certificateInfo.notAfter.value}
                              </div>
                        </div>
                        {
                          this.props.certificateInfo.extensionsCertificateInfo.value.isElectronicStamp !== undefined
                          ? <div className="input-group">
                                <label className="col-md-4 col-lg-4 col-sm-12 col-xs-12 font-weight-bold margin-bottom-sm">{this.props.certificateInfo.extensionsCertificateInfo.value.isElectronicStamp.description}</label>
                                  <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12 margin-bottom-8">
                                    {this.props.certificateInfo.extensionsCertificateInfo.value.isElectronicStamp.value === "true" ? i18next.t("yesUCase") : i18next.t("noUCase")}
                                  </div>
                            </div>
                          : null
                        }
                        
                        <div className="input-group">
                            <label className="col-md-4 col-lg-4 col-sm-12 col-xs-12 font-weight-bold margin-bottom-sm">{this.props.certificateInfo.extensionsCertificateInfo.value.isQualified.description}</label>
                              <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12 margin-bottom-8">
                                {this.props.certificateInfo.extensionsCertificateInfo.value.isQualified.value === "true" ? i18next.t("yesUCase") : i18next.t("noUCase")}
                              </div>
                        </div>
                        <div className="input-group">
                            <label className="col-md-4 col-lg-4 col-sm-12 col-xs-12 font-weight-bold margin-bottom-sm">{this.props.certificateInfo.extensionsCertificateInfo.value.isPrivateKeyOnQSCD.description}</label>
                              <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12 margin-bottom-8">
                                {this.props.certificateInfo.extensionsCertificateInfo.value.isPrivateKeyOnQSCD.value === "true" ? i18next.t("yesUCase") : i18next.t("noUCase")}
                              </div>
                        </div>
                        <div className="input-group">
                            <label className="col-md-4 col-lg-4 col-sm-12 col-xs-12 font-weight-bold margin-bottom-sm">{this.props.certificateInfo.extensionsCertificateInfo.value.qscdTypeName.description}</label>
                              <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12 margin-bottom-8">
                                {this.props.certificateInfo.extensionsCertificateInfo.value.qscdTypeName.value === null ? i18next.t("ds:notDefined") : this.props.certificateInfo.extensionsCertificateInfo.value.qscdTypeName.value}
                              </div>
                        </div>
                      </div>
                  }
                  </div>
                  &nbsp;
                  <div className="card">
            <div className="card-header">
              {i18next.t("cert:ownerInfoTitle")}
            </div>
            <div className="card-body left-padding-5">
                {
                  this._renderCertInfo()
                }
                </div>
            </div>
            &nbsp;
            <div className="card">
            <div className="card-header">
              {i18next.t("cert:issuerInfoTitle")}
            </div>
            <div className="card-body left-padding-5">
                {
                  this._renderIssuerInfo()
                }
                </div>
            </div>
            &nbsp;
            <div className="card">
            <div className="card-header">
              {i18next.t("cert:publicKeyInfoBodyListTitle")}
            </div>
            <div className="card-body left-padding-5">
                {
                  this._renderPublicKeyInfo()
                }
                </div>
            </div>
            &nbsp;
        </div> : <div></div>
    );
  }
}

function mapStateToProps(state) {
    return {
        base: state.base,
        localesReducer: state.localesReducer,
        language: state.localesReducer.language,
        certificateInfo: state.certificateInfo.certInfoSignature
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CertificateInfoSignature);