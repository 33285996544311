import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18next from 'i18next'
import { bindActionCreators } from 'redux'
import { languageSwitchItem, getCurrentLanguage } from '../../actions/locales'
import { getSupportedCA, getConnecteds, changeServiceLanguage } from '../../actions/api'
import { setListCSK, setPassiveTokenPath, setActiveTokenPath, showVisualImpairmentView, setContrast, setFontSize } from '../../actions/localStates'
import ServiceConnectionStatus from './ServiceConnectionStatus'
import Downloads from './Downloads'
import CountDownTimer from './CountDownTimer'
import '../../css/sjwsa-client-kredobank.css'

import logo from '../../img/logo.png'

const availableLanguege = ["uk", "pl", "en"];
const availableLanguegeValue = ["УКР", "POL", "ENG"];

class Header extends Component {

	constructor(props) {
    super(props);

		this.showVisualImpairmentView = this.showVisualImpairmentView.bind(this)
		this.setContrast = this.setContrast.bind(this)

		this.setFontSizePlus = this.setFontSizePlus.bind(this)
		this.setFontSizeMinus = this.setFontSizeMinus.bind(this)
		this.setFontSizeReset = this.setFontSizeReset.bind(this)

	}

	showVisualImpairmentView () {
		this.props.actions.showVisualImpairmentView(!this.props.baseUrls.showVisualImpairmentView)
	}

	setContrast () {
		this.props.actions.setContrast(!this.props.baseUrls.contrast)
	}

	setFontSizePlus () {
		if (this.props.baseUrls.fontSize < 4) {
			this.props.actions.setFontSize(this.props.baseUrls.fontSize + 1)
		} else {
			this.props.actions.setFontSize(4)
		}
	}

	setFontSizeMinus () {
		if (this.props.baseUrls.fontSize > 0) {
			this.props.actions.setFontSize(this.props.baseUrls.fontSize - 1)
		} else {
			this.props.actions.setFontSize(0)
		}
	}

	setFontSizeReset () {
		this.props.actions.setFontSize(0)
	}

	componentDidMount() {
		i18next.changeLanguage('uk');
		this.props.actions.getCurrentLanguage('uk');
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.connectionStatus.baseUrl !== null) {
			if (nextProps.connectionStatus.baseUrl !== this.props.connectionStatus.baseUrl) {
				this.props.actions.getSupportedCA(nextProps.connectionStatus.baseUrl)
				.then((response) => {
					this.props.actions.setListCSK(response.ca)
				})

				if (nextProps.connectionStatus.uccConnection) {
					this.props.actions.getConnecteds(nextProps.connectionStatus.baseUrl)
			    	.then((response) => {
			    		console.log(response);
			    		if (response.passiveTokenPath.length > 0) {
			    			this.props.actions.setPassiveTokenPath(response.passiveTokenPath)
			    		}
			    		if (response.activeTokenPath.length > 0) {
			    			this.props.actions.setActiveTokenPath(response.activeTokenPath)
			    		}
			    	})
			    }
			}
		} else {
			return
		}
	}

	changeLang(lang){
		this.props.actions.getCurrentLanguage(lang);
        this.props.actions.languageSwitchItem('eng', lang, availableLanguege);
        this.props.actions.changeServiceLanguage(this.props.connectionStatus.baseUrl, lang);
    }

    _renderLanguageButtonLine() {

	    function options(child, index) {
	      let activeClass = (this.props.language === child ? 'btn btn-outline-info btn-sm' : 'btn btn-default btn-sm');
	      let textValue = (availableLanguegeValue[index])

	      return (
	        <button key={child} className={activeClass} style={{marginLeft: "7px"}} onClick={this.changeLang.bind(this, child)}>{textValue}</button>
	      );
	    }

	    return (
	    	<div id="languageButtonLine" className="col-md-9 col-sm-9 col-xs-9 text-right">
	    		{availableLanguege.map(options.bind(this))}
	        </div>
	    );
	}

	render() {
		return (
			<div className="col-md-12 col-sm-12 col-xs-12">
				<div>
		        	{
		        		this.props.baseUrls.showVisualImpairmentView
		        		? <div className="margin-padding-0" style={{paddingTop: "7px", paddingBottom: "7px"}}>
		        			<div className="visual-impairment">
			        			<div className="visual-impairment-container">
				        			<div className="visual-impairment-div">
				        				<div className="visual-impairment-buttons">
				        					<div className="visual-impairment-filter">
				        						<div className="visual-impairment-title">
				        							{i18next.t("contrast")}
				        						</div>
				        						<button className="btn visual-impairment-panel-buttons" onClick={this.setContrast}><i class="fa fa fa-adjust"></i></button>
				        					</div>
				        					<div className="visual-impairment-panel-size">
				        						<button className="btn visual-impairment-panel-buttons" onClick={this.setFontSizePlus}><i className="fa fa-plus"></i></button>
				        						<button className="btn visual-impairment-panel-buttons" onClick={this.setFontSizeMinus}><i className="fa fa-minus"></i></button>
				        						<button className="btn visual-impairment-panel-buttons" onClick={this.setFontSizeReset}><i className="fa fa-rotate-right revert-img"></i></button>
				        					</div>
					        			</div>
					        			<div className="visual-impairment-hide">
					        				<button className="btn visual-impairment-panel-buttons" onClick={this.showVisualImpairmentView}><i className="fa fa-times"></i></button>
					        			</div>
				        			</div>
				        		</div>
			        		</div>
			        	</div>
		        		: null
		        	}
		        </div>

				<div className="row mtb-default">
			        <div className="col-lg-3 col-md-4 col-sm-4 col-5 text-left">
			            <img className="img" src={logo} width="243" height="75" alt="Logo" />
			        </div>
			        <div className="col-lg-6 col-md-8 col-sm-12 col-12 text-left text-muted">
			            <h4 style={{marginLeft: "5px"}} className="d-none d-sm-none d-md-block kredobank-title" id="clientForService">{i18next.t("kredobank")}</h4>
			            <h4 style={{marginLeft: "5px"}} className="d-none d-sm-none d-md-none kredobank-title" id="clientForService">{i18next.t("kredobank")}</h4>
			            
			            <span className="d-none d-sm-none d-md-block kredobank-page-title" id="titleCipherBis">{i18next.t("clientForService")}</span>
			        </div>
			        <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12 text-center text-muted">
			        	<ServiceConnectionStatus connectionStatus={this.props.connectionStatus} url={this.props.baseUrls} />
			        </div>
			    </div>
			    <div className="row mtb-default">
			        <CountDownTimer /> {this._renderLanguageButtonLine()}
			    </div>
			    <div style={{textAlign: "right", paddingTop: "7px"}}>
		        	<span style={{cursor: "pointer"}} onClick={this.showVisualImpairmentView}><i class="fa fa-low-vision"></i>&nbsp;{i18next.t("visualImpairment")}</span>
		        </div>

			    <hr />
			    <Downloads connectionStatus={this.props.connectionStatus.status} url={this.props.baseUrls} />		    
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        localesReducer: state.localesReducer,
        language: state.localesReducer.language,
        connectionStatus: state.connectionStatusReucer,
        baseUrls: state.base
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
	    languageSwitchItem,
	    getCurrentLanguage,
	    getSupportedCA,
	    setListCSK,
	    getConnecteds,
	    setPassiveTokenPath,
	    setActiveTokenPath,
	    changeServiceLanguage, showVisualImpairmentView, setContrast, setFontSize
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);